import { Edge, EdgeProps, Node, NodeProps } from "react-flow-renderer";
import { KeyValueType, OptionType, VoiceSettingsType } from ".";

export type AlternativeEscapeType = {
  _id: string;
  scapeId: string;
  scapeStatus: OptionType<string>[];
};

export type AnswersNodeDataType = {
  item: ItemFlowType;
  answers?: AnswersType;
};

export type AnswersNodeFlow = Node<AnswersNodeDataType>;

export type AnswersNodeFlowProp = NodeProps<AnswersNodeDataType>;

export type AnswersType = {
  amountSentMessages: number;
  item: ItemFlowType;
  messages: MessagesAnswersType[];
};

export type IdKeyValueType = KeyValueType<string> & { _id: string };

export type EdgeDataType = {};

export type EdgeFlow = Edge<EdgeDataType>;

export type EdgeFlowProp = EdgeProps<EdgeDataType>;

export type ElementFlowType = keyof typeof ItemFlowTypeEnum;

type EscapeType = {
  toleranceTimeScape?: number;
  defaultScape?: string;
  alternativeScapes: AlternativeEscapeType[];
};

export type FieldOptionType<T> = {
  _id: string;
  fieldValue: T;
  fieldName: string;
};

export type FilterComparatorType = {
  type: FilterConditionEnum;
  value: string;
};

export enum FilterConditionEnum {
  loadField = "loadField",
  currentDate = "currentDate",
  currentDatePlus = "currentDatePlus",
  currentDateMinus = "currentDateMinus",
  fixedValue = "fixedValue",
  operator = "operator",
  formula = "formula",
  undefined = "undefined",
}

export type FilterConditionType = {
  _id: string;
  conditional_operator?: FilterOperatorType;
  field: string;
  comparison_operator: string;
  field_to_compare: FilterComparatorType;
};

export type FilterSegmentsType = {
  _id: string;
  conditions: FilterConditionType[];
  conditional_operator?: FilterOperatorType | undefined;
};

export type FilterOperatorType = "and" | "or";

export type FlowAnswersType = {
  nodes: AnswersNodeFlow[];
  edges: EdgeFlow[];
};

export type OperatorType =
  | "equal"
  | "not_equal"
  | "includes"
  | "not_includes"
  | "greater_than"
  | "greater_than_or_equal"
  | "less_than"
  | "less_than_or_equal"
  | "included"
  | "not_included";

export type ConditionsType = {
  id: String;
  operator: OperatorType;
  value: String;
  destination: string;
  variable: String;
};

export type FlowsUpType = {
  nodes: ReportsNodeFlow[];
  edges: EdgeFlow[];
};

export type ItemFlowType = {
  orchSettings: OrchSettingsType;
  voiceSettings: VoiceSettingsType;
  carousel: {
    cards: [];
  };
  zendesk: {
    customList: [];
  };
  botTransfer: {
    exportVariables: boolean;
  };
  glpi: {
    customList: [];
    requirePassword: boolean;
  };
  version: number;
  description: [];
  isOptOut: boolean;
  isOptIn: boolean;
  botFromBoteriaId: string;
  waitForTheAnswer: boolean;
  captureAnswer: boolean;
  orphan: boolean;
  positionOnScreen: [number, number];
  type: ElementFlowType;
  randomTextActive: boolean;
  timeTypping: number;
  tags: string[];
  acceptFiles: [];
  waitResponse: boolean;
  useIA: boolean;
  checkAgent: boolean;
  respectSchedule: boolean;
  lockConnectorFrom: boolean;
  suggestionList: [];
  quickAccess: boolean;
  _id: string;
  botId: string;
  title: string;
  menuItems?: MenuItemType[];
  position: number;
  methodType: string;
  conditions: ConditionsType[];
  headers?: IdKeyValueType[];
  apiV2Variables?: IdKeyValueType[];
  questions: [];
  __v: number;
  optOutItem: string;
  optOutListOption: string;
  optInItem: string;
  optInListOption: string;
  variable: string;
  message: string;
  subject: string;
  html?: boolean;
  jsonPayload: string;
  urlEndpoint: string;
  messageApiError: string;
  timeout?: number;
  delivery_message_time?: string;
  filters_segments?: FilterSegmentsType[];
  segmentsLoadLayout?: string;
  messagesPerSegments?: MessagePerSegmentsType[];
  apiScapeId: string;
  fileUrl: string;
  fileTitle: string;
  thumbnailUrl: string;
  useMedia: boolean;
  currentMedia?: string;
  waitingTime?: number;
  waitingTimeType?: "minutes" | "hours";
  watchTabulation?: boolean;
  dialerCustomFields?: string[];
};

export enum ItemFlowTypeEnum {
  OrchSMS = "sms",
  OrchEmail = "email",
  OrchHSM = "hsm",
  Conditional = "conditional",
  APIV2 = "api",
  OrchBoteria = "boteria",
  OrchScale = "trigger",
  Timer = 'timer',
  Dialer = 'dialer',
  Start = 'start',
}

export type MenuItemType = {
  _id: string;
  description: string;
  listOptionId?: string;
};

export type MessagesAnswersType = {
  menuItemName: string;
  countMenuItem: number;
  answersInMenuItem: any[];
};

export type MessagePerSegmentsType = {
  _id: string;
  value: string;
  segmentId?: string;
};

export type NodeDataType = ItemFlowType & { label?: string };

export type NodeFlow = Node<NodeDataType>;

export type NodeFlowProp = NodeProps<NodeDataType>;

export type NodeHeaderType = {
  icon: JSX.Element;
  menu: string;
};

type OrchSettingsType = {
  allowEmailAnswer?: boolean;
  scape: EscapeType;
  campaignCodeHSM?: number;
  namespaceCodeHSM?: number;
  numberchip?: string;
  templateMessage?: string;
  listFieldsHSM: FieldOptionType<string>[];
  listFieldsSendfyAssociations?: FieldOptionType<string>[];
  affirmativeButtonColor?: string;
  negativeButtonColor?: string;
  affirmativeTextField?: string;
  negativeTextField?: string;
  isTemplateMessage?: boolean;
  resendAttempts?: number;
  htmlContentEmailAnswer?: string;
  keep_template_HSM_hooks?: boolean;
};

export type ReportNodeDataType = {
  item?: ItemFlowType;
  reports?: ReportsType[];
  label?: string;
};

export type ReportsNodeFlow = Node<ReportNodeDataType>;

export type ReportsNodeFlowProp = NodeProps<ReportNodeDataType>;

export type ReportsType = {
  id_item: string;
  contacts: number;
  sent?: number;
  delivered?: number;
  read?: number;
  answered?: number;
  processing?: number;
  processed?: number;
  click?: number;
  open?: number;
  unsubscribe?: number;
  spam_report?: number;
  scaped?: number;
  error?: number;
  blocked?: number;
  finished?: number;
  expired?: number;
  retry?: number;
};
