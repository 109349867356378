import {
  BusinessHourType,
  ChannelType,
  EmailProvider,
  ItemIDType,
  OptionType,
  PlatformType,
  ReportType,
  TriggerType,
  VersionType,
  VoiceSettingsType,
} from ".";

export type AttendanceScheduleType = {  
  fuso?: string;
  fusoName?: string;
  businessHours: BusinessHourType[];
};

type BoteriaSettingType = {
  token: string;
  user_id: string;
};

type DialerSettingsType = {
  login: string,
  password: string,
  projectURL: string,
  projectID: string,
  campaignID: string,
};

type VtexSettingType = {
  userId: string;
}

export type ChannelsSettingsType = {
  storeContactNumber: boolean;
  storeMessageHistory: boolean;
  messageDefault: string;
  exposeContactNumber: boolean;
};

export type CountsType = {
  sessions: number;
  botMessages: number;
  userMessages: number;
};

export type EmailSettingType = LoginSettingType & {
  host: string;
  port: number;
  from_email: string;
  from_name: string;
  sendgrid: {
    username: string;
    idApiKey: string;
    apiKey: string;
  };
  orchEmailProvider: EmailProvider;
};

type GLPISettingsType = {
  apiUrl: string;
  appToken: string;
  userToken: string;
};

type HSMSettingType = LoginSettingType & {
  numberchip: string;
};

type HumanSettingType = {
  endPoints: object;
  zendesk: object;
  genesys: {
    clientId: string;
    clientSecret: string;
    genesysCloud: string;
  };
  selected: "Zendesk" | "Omni" | "Ayty" | "Genesys Cloud";
};

export type IAType = {
  selected: String;
  platforms: PlatformType[];
};

type JiraSettingsType = {
  nameSpace: string;
  requestTypeDefault: string;
  projectIdDefault: string;
  statusIdDefault: string;
  email: string;
  tokenAPI: string;
  validateLogin: boolean;
};

type FTPSettingsType = {
  host?: string;
  username?: string;
  password?: string;
  port?: number;
  filePath?: string;
  fileName?: string;
  active: boolean;
};

export type JourneySettingsType = {
  loadRunningDays: Number;
  email?: EmailSettingType;
  sms?: SMSSettingType;
  hsm?: HSMSettingType;
  boteria?: BoteriaSettingType;
  dialer?: DialerSettingsType;
  ftp?: FTPSettingsType;
  vtex?: VtexSettingType;
  storage: StorageSettingType;
  human: HumanSettingType;
  defaultToleranceTimeScape: number;
  parserCSV?: OptionType<string>;
  zendeskTicket: ZendeskSettingType;
  glpiSettings: GLPISettingsType;
  jiraSettings: JiraSettingsType;
  timezone: TimezoneSettingType;
  attendanceSchedule: AttendanceScheduleType;
  sending_mode: string;
  denyList: DenyListSettings;
};

export type JourneyType = {
  __v: number;
  _id: string;
  title: string;
  type: JourneyTypeEnum;
  description: string;
  organizationId: string;
  companyId: string;
  versions: VersionType[];
  voicebotInstanceId: string;
  voiceSettings: VoiceSettingsType;
  img: string;
  viewPosition: ViewPositionType;
  user: string;
  widgetHtml: WidgetHtmlType;
  rootItem: string;
  counts: CountsType;
  currentTokenIa: string;
  settings: JourneySettingsType;
  ia: IAType;
  creationDate: string;
  isActive: boolean;
  iaStatus: boolean;
  channelsSettings: ChannelsSettingsType;
  channels: ChannelType[];
  singleChannel?: string; 
  lastTrainedAt: string;
  iaEnabled: boolean;
  lastUpdate: string;
  deleted: boolean;
  deleteDate: string;
  triggers: TriggerType[];
  maxTimeWithoutResponse: string;
  locked: "webchat" | "whatsapp" | "messenger";
  isTemplate: boolean;
  fromTemplate: string;
  copiedFrom: string;
  reports: ReportType[];
  countUpdatesNoPublish: number;
  channel: 'HSM' | 'SMS' | 'Email';
};

export enum JourneyTypeEnum {
  Orquestrador = "orquestrador",
  Scale = "scale",
  Single = "single"
}

export type JourneyViewType = {
  __v: number;
  _id: string;
  title: string;
  type: "chat" | "voice" | "orquestrador";
  description: string;
  organizationId: string;
  companyId: string;
  versions: VersionType[];
  settings: JourneySettingsType;
  viewPosition: ViewPositionType;
  ia: IAType;
  creationDate: string;
  isActive: boolean;
  iaStatus: boolean;
  isTemplate: boolean;
  channelsSettings: ChannelsSettingsType;
  channels: ChannelType[];
  lastTrainedAt: string;
  iaEnabled: boolean;
  lastUpdate: string;
  deleted: boolean;
  deleteDate: string;
  triggers: TriggerType[];
  reports: ReportType[];
  locked: "webchat" | "whatsapp" | "messenger";
  countUpdatesNoPublish: number;
  version: number;
};

type LoginSettingType = {
  username: string;
  password: string;
};

type SMSSettingType = LoginSettingType & {
  campaign: string;
  blockLargeMessages: boolean;
};

type StorageSettingType = {
  project: string;
  storage: string;
  key: string;
};

type TimezoneSettingType = {
  fuso: string;
  fusoName: string;
  businessHours: BusinessHourType[];
  escape: {
    to: "message" | "item";
    message: string;
    itemId: ItemIDType[];
  };
};

export type ViewPositionType = {
  panX: number;
  panY: number;
  zoomLevel: number;
};

export type WidgetHtmlType = {
  primaryColor: string;
  secondaryColor: string;
  headerName: string;
  domains: string;
  botFab: string;
};

type ZendeskSettingType = {
  nameSpace: string;
  apiToken: string;
};

export type HSMMediaType = 'image' | 'video' | 'audio' |  'document' | 'N/A';

export type SendfyHSMTemplate = {
  template: string;
  description: string;
  phrase: string;
  numberFields: number;
  hasMedia: HSMMediaType;
  conversationCategory: string;
  templateName: string;
  quality: string;
  active: boolean;
};

export type DenyListSettings = {
  denyLists: string[];
  useDenyList: boolean;
}