import { validCNPJ } from "consts";
import {
  FilterConditionEnum,
  ItemFlowType,
  JourneySettingsType,
  JourneyType,
  JourneyTypeEnum,
  LoadLayoutFieldsType,
} from "models";

export const isValidField = (value: unknown) => {
  if (value === undefined || value === null) return false;
  if (typeof value === "string" && value.trim() === "") return false;
  if (typeof value === "boolean") return true;

  return true;
};

export const validateAPIItem = (item: ItemFlowType) => {
  const result = [];

  if (item?.methodType === "GET") {
    if (!isValidField(item?.urlEndpoint)) result.push("errors.invalidURL");
  } else {
    if (!isValidField(item?.urlEndpoint)) result.push("errors.invalidURL");
    if (!isValidField(item?.methodType)) result.push("errors.invalidMethod");
    if (!isValidField(item?.jsonPayload)) result.push("errors.invalidJson");
  }

  return result;
};

export const validateBoteriaItem = (item: ItemFlowType) => {
  const result = [];

  if (!isValidField(item.botFromBoteriaId))
    result.push("errors.invalidTargetBot");

  if (item.orchSettings.isTemplateMessage) {
    if (!isValidField(item.orchSettings.templateMessage))
      result.push("errors.invalidTemplateMessage");

    if (!isValidField(item.orchSettings.listFieldsHSM))
      result.push("errors.invalidListField");
  } else {
    if (!isValidField(item.message)) result.push("errors.invalidMessage");
  }

  return result;
};

export const validateBoteriaSettings = (settings: JourneySettingsType) => {
  const result = [];
  const boteria = settings.boteria;

  if (!boteria?.token || !boteria?.user_id)
    result.push("errors.incompleteBoteriaIntegration");

  return result;
};

export const validateCNPJ = (value?: string) => {
  if (value && validCNPJ.test(value)) {
    const currentValue = (value.match(/\d/g) || []).map(Number);
    const digits = currentValue?.slice(12);

    if (new Set(currentValue).size === 1) return false;

    const calc = (x: number) => {
      const slice = currentValue.slice(0, x);
      let factor = x - 7;
      let sum = 0;

      for (let i = x; i >= 1; i--) {
        const n = slice[x - i];
        sum += n * factor--;
        if (factor < 2) factor = 9;
      }

      const result = 11 - (sum % 11);

      return result > 9 ? 0 : result;
    };

    const first = calc(12);
    if (first !== digits[0]) return false;

    const second = calc(13);
    return second === digits[1];
  }

  return false;
};

export const validateLoadLayoutFields = (fields: LoadLayoutFieldsType[]) : [boolean, string] => {
  if (fields.length) {
    let identifierCount = 0;
    let nameCount = 0;
    let contactCount = 0;
    fields.forEach(({ type }) => {
      switch (type) {
        case "identifier":
          identifierCount += 1;
          break;
        case "name":
          nameCount += 1;
          break;
        case "email":
        case "phone":
          contactCount += 1;
          break;
        default:
          break;
      }
    });

    if (identifierCount !== 1) return [false, "errors.invalidLoadLayoutMoreThanOneId"];
    if (nameCount > 1) return [false, "errors.invalidLoadLayoutMoreThanOneName"];
    if (contactCount === 0) return [false, "errors.invalidLoadLayoutNoContactFields"];

    return [true, ""];
  }

  return [false,"errors.invalidLoadLayoutNoFields"];
};

export const validateDate = (date: string) => {
  return !isNaN(Date.parse(date));
};

export const validateEmailItem = (item: ItemFlowType) => {
  const result = [];

  if (!item.orchSettings.allowEmailAnswer) {
    if (!isValidField(item?.subject)) result.push("errors.invalidSubject");
    if (!isValidField(item?.message)) result.push("errors.invalidMessage");
  } else {
    if (!isValidField(item?.subject)) result.push("errors.invalidSubject");
    if (!isValidField(item?.message)) result.push("errors.invalidMessage");
    // if (!isValidField(item.orchSettings?.affirmativeTextField))
    //   result.push("errors.invalidAffirmativeText");
    // if (!isValidField(item.orchSettings?.affirmativeButtonColor))
    //   result.push("errors.invalidAffirmativeButton");
    // if (!isValidField(item.orchSettings?.negativeTextField))
    //   result.push("errors.invalidNegativeText");
    // if (!isValidField(item.orchSettings?.negativeButtonColor))
    //   result.push("errors.invalidNegativeButton");
  }

  return result;
};

export const validateEmailSettings = (settings: JourneySettingsType) => {
  const result = [];
  const email = settings.email;
  const provider = email?.orchEmailProvider;

  if (!provider) result.push("errors.incompleteEmailChannel");

  if (provider === "sendfy" && (!email?.username || !email?.password))
    result.push("errors.incompleteEmailChannel");

  return result;
};

export const validateHSMItem = (item: ItemFlowType) => {
  const result = [];

  if (
    item.orchSettings?.listFieldsHSM?.filter(
      (field) => field?.fieldValue === ""
    ).length > 0
  ) {
    result.push("errors.fieldEmpty");
  }

  if (!isValidField(item.orchSettings.numberchip))
    result.push("errors.invalidNumberchip");

  if (item.orchSettings.isTemplateMessage) {
    if (!isValidField(item.orchSettings.templateMessage))
      result.push("errors.invalidTemplateMessage");

    if (!isValidField(item.orchSettings.listFieldsHSM))
      result.push("errors.invalidListField");
  } else {
    if (!isValidField(item.message)) result.push("errors.invalidMessage");
  }

  if (item.useMedia && !item.fileUrl) {
    result.push("errors.missingFile");
  }

  return result;
};
export const validateConditiontem = (item: ItemFlowType) => {
  const result = [];

  if (item.conditions.length === 0) {
    result.push("errors.emptyConditions");
  }

  item?.conditions?.forEach((e) => {
    if (!e.destination || !e.operator || !e.value || !e.variable)
      result.push("errors.invalidConditions");
  });

  return result;
};
export const validateTimerItem = (item: ItemFlowType) => {
  const result = [];

  if (!item.waitingTime || item.waitingTime <= 0) {
    result.push("errors.zeroWaitingTime");
  }

  return result;
};

export const validateDialerItem = (item: ItemFlowType) => {
  const result = [];
  if ((item?.dialerCustomFields?.length || 0) > 20) {
    result.push("errors.toManyCustomFields");
  }
  return result;
};
export const validateDiallerSettings = (settings: JourneySettingsType) => {
  const result = [];
  const dialer = settings.dialer;

  if (!dialer?.login || !dialer?.password || !dialer?.projectID || !dialer?.projectURL || !dialer?.campaignID)
    result.push("errors.incompleteLigoDialerIntegration");

  return result;
};
export const validateHSMSettings = (settings: JourneySettingsType) => {
  const result = [];
  const hsm = settings.hsm;

  if (!hsm?.username || !hsm?.password)
    result.push("errors.incompleteHSMChannel");

  return result;
};

export const validateItems = (journey: JourneyType, items: ItemFlowType[]) => {
  const errors = new Set<string>();

  if (!items.length) errors.add("errors.emptyJourney");

  let triggerCount = 0;

  items.forEach((item) => {
    switch (item.type) {
      case "OrchSMS":
        validateSMSSettings(journey.settings).forEach((e) => errors.add(e));
        validateSMSItem(item).forEach((e) => errors.add(e));
        break;

      case "OrchHSM":
        item.orchSettings?.listFieldsHSM?.filter(
          (field) => field?.fieldValue === ""
        ).length > 0 && errors.add("errors.fieldEmpty");
        validateHSMSettings(journey.settings).forEach((e) => errors.add(e));
        validateHSMItem(item).forEach((e) => errors.add(e));
        break;

      case "OrchEmail":
        validateEmailSettings(journey.settings).forEach((e) => errors.add(e));
        validateEmailItem(item).forEach((e) => errors.add(e));
        break;

      case "APIV2":
        validateAPIItem(item).forEach((e) => errors.add(e));
        break;
      case "Conditional":
        validateConditiontem(item).forEach((e) => errors.add(e));
        break;

      case "OrchBoteria":
        validateBoteriaSettings(journey.settings).forEach((e) => errors.add(e));
        validateBoteriaItem(item).forEach((e) => errors.add(e));
        break;

      case "OrchScale":
        triggerCount += 1;
        validateTriggerItem(item).forEach((e) => errors.add(e));
        break;
      case "Timer":
        validateTimerItem(item).forEach((e) => errors.add(e));
        break;
      case "Dialer":  
        validateDiallerSettings(journey.settings).forEach((e) => errors.add(e));
        validateDialerItem(item).forEach((e) => errors.add(e));
        break;
      default:
        break;
    }
  });

  if (journey.type === JourneyTypeEnum.Scale && triggerCount === 0)
    errors.add("errors.insufficientTriggers");

  return Array.from(errors);
};

export const validateSMSItem = (item: ItemFlowType) => {
  const result = [];
  if (!isValidField(item.message)) result.push("errors.invalidMessage");
  return result;
};

export const validateSMSSettings = (settings: JourneySettingsType) => {
  const result = [];
  const sms = settings.sms;

  if (!sms?.campaign || !sms?.username || !sms?.password)
    result.push("errors.incompleteSMSChannel");

  return result;
};

export const validateTriggerItem = (item: ItemFlowType) => {
  const result = [];

  const filterConditionCount = item.filters_segments?.length || 0;

  if (filterConditionCount === 0) result.push("errors.emptyFilterCondition");

  item.filters_segments?.forEach((segment, index) => {
    if (index > 0 && !segment.conditional_operator)
      result.push("errors.invalidConditionalOperatorBetweenGroups");

    segment.conditions.forEach((condition, index) => {
      if (!condition.field) result.push("errors.invalidField");

      if (!condition.comparison_operator)
        result.push("errors.invalidCondition");

      if (index > 0 && !condition.conditional_operator)
        result.push("errors.invalidConditionalOperatorBetweenConditions");

      if (
        condition.field_to_compare.type !== FilterConditionEnum.currentDate &&
        !condition.field_to_compare.value
      )
        result.push("errors.invalidFieldToCompareValue");
    });
  });

  return result;
};
